<template>
  <div class="review border-radius-s bg-bg">
    <block class="">
      <div class="user width-expand padding padding-s-bottom">
        <div class="flex gap-m flex-middle">
          <div
            class="avatar border-thin-gray-1 flex-center flex-middle bg-lead radius-round"
            style="height: 40px; width: 40px; flex: 0 0 40px">
            <span class="text-xl text-bold c-bg">ש</span>
          </div>
          <div class="user__content flex flex-column width-expand">
            <div class="name date name-date flex-between flex-middle width-expand">
              <span class="text-l bold">ישראל ישראלי</span>
              <span class="text-s">{{ translateDate(new Date(), "short") }}</span>
            </div>
            <div class="verified flex">
              <badge theme="success">
                <icon-text icon="check">
                  {{ translateUcFirst("ecommerce.review.verifiedBuyer") }}
                </icon-text>
              </badge>
            </div>
          </div>
        </div>
      </div>
      <hr class="margin-remove" />
      <div class="content padding-m cursor-pointer" @click="expanded = !expanded">
        <p v-if="!expanded" class="animate__fadeIn animate--fast margin-remove">
          לורם איפסום דולור סיט אמט, קונסקטורר אדיפיסינג אלית הועניב...
        </p>
        <p v-if="expanded" class="animate__fadeIn animate--fast margin-remove">
          נולום ארווס סאפיאן - פוסיליס קוויס, אקווזמן לורם איפסום דולור סיט אמט, קונסקטורר
          אדיפיסינג אלית. סת אלמנקום ניסי נון ניבאה. דס איאקוליס וולופטה דיאם. וסטיבולום
          אט דולור, קראס אגת לקטוס וואל אאוגו וסטיבולום סוליסי טידום בעליק. צש בליא,
          מנסוטו צמלח לביקו ננבי, צמוקו בלוקריה שיצמה ברורק. גולר מונפרר סוברט לורם שבצק
          יהול, לכנוץ בעריר גק ליץ, ליבם סולגק. בראיט ולחת צורק מונחף, בגורמי מגמש. תרבנך
          וסתעד לכנו סתשם השמה - לתכי מורגם בורק? לתיג ישבעס.
        </p>
        <div class="actions margin-m-top">
          <span v-if="expanded" class="c-lead inline-block animate__fadeIn animate--fast">
          <icon-text icon-position="end" icon="chevron-up">
            {{ translateUcFirst("core.showLess") }}
          </icon-text>
        </span>
          <span v-if="!expanded" class="c-lead inline-block  animate__fadeIn animate--fast">
          <icon-text icon-position="end" icon="chevron-inline-end">
            {{ translateUcFirst("core.showMore") }}
          </icon-text>
        </span>
        </div>

      </div>
      <hr class="margin-remove" />
      <div class="grade padding-s-vertical padding-m-horizontal bg-inverse c-bg">
        <div class="grade-unit flex-between flex-middle flex-wrap">
          <span>{{ translateUcFirst("ecommerce.review.productRatingLabel") }}</span>
          <div
            class="product-rating flex flex-center gap-s ltr padding-m-horizontal padding-s-vertical">
            <div class="review-stars flex gap-s font-size-s ltr">
              <icon icon="star" theme="lead"></icon>
              <icon icon="star" theme="lead"></icon>
              <icon icon="star" theme="lead"></icon>
              <icon icon="star" theme="lead"></icon>
              <icon icon="star" theme="gray"></icon>
            </div>
            <span class="ltr">4.5</span>
          </div>
        </div>

        <div class="grade-unit flex-between flex-middle flex-wrap">
          <span>{{ translateUcFirst("ecommerce.review.vendorRatingLabel") }}</span>
          <div
            class="vendor-rating flex flex-center gap-s ltr padding-m-horizontal padding-s-vertical">
            <div class="review-stars flex gap-s font-size-s ltr">
              <icon icon="star" theme="lead"></icon>
              <icon icon="star" theme="lead"></icon>
              <icon icon="star" theme="lead"></icon>
              <icon icon="star" theme="lead"></icon>
              <icon icon="star" theme="gray"></icon>
            </div>
            <span class="ltr">4.5</span>
          </div>
        </div>
      </div>

    </block>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      expanded: false,
    };
  },
};
</script>

<style scoped lang="scss">
.review {
  min-height: 200px;
  min-width: 300px;
  flex: 300px;
  max-width: 100%;
  border: 1px solid var(--c-gray-2);
}
</style>
